<template>
  <div class="login-questions-page">
    <div class="section-content mx-auto">
      <div class="login-questions-header d-flex flex-wrap align-start justify-space-between">
        <h1 class="order-1 order-sm-0 flama-bold--font">Tell us about you</h1>
        <span class="mb-2 mb-sm-0 primary--text">Step 1 of 2</span>
      </div>

      <div class="login-questions-item mb-12">
        <h2 class="flama-bold--font mb-1 mb-sm-3">What describes you best?</h2>
        <v-select
          hide-details
          v-model="answers.about"
          :items="about"
          placeholder="Select Your answer"
        ></v-select>
      </div>

      <div class="login-questions-item mb-12">
        <h2 class="flama-bold--font mb-1 mb-sm-3">Are you already selling?</h2>
        <v-select
          hide-details
          v-model="answers.sellingStatus"
          :items="sellingStatus"
        ></v-select>
      </div>

      <div class="login-questions-item mb-12">
        <h2 class="flama-bold--font mb-1 mb-sm-3">What is your currently monthly revenue?</h2>
        <v-select
          hide-details
          v-model="answers.revenue"
          :items="revenue"
        ></v-select>
      </div>

      <div class="login-questions-buttons d-flex">
        <v-btn
          dark
          depressed
          outlined
          type="submit"
          height="auto"
          color="grey-4"
          class="py-4 mr-6"
          @click="register(true)"
        >
          Skip
        </v-btn>
        <v-btn
          dark
          depressed
          type="submit"
          height="auto"
          color="black"
          class="py-4"
          @click="register"
        >
          <img class="mr-2" width="24" height="24" :src="require('@/assets/img/icons/ic-arrow-right-white.svg')"
               alt="">
          Next
        </v-btn>
      </div>

      <div class="error--text mt-4" v-if="$store.getters.getErrorMessage">
        {{$store.getters.getErrorMessage}}
      </div>

      <p class="mt-6 grey-7--text">Do you already have an account? <router-link :to="{name: 'Login'}">Log in</router-link> </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginQuestions',
  data() {
    return {
      about: ['I am a Seller', 'I am a Customer'],
      sellingStatus: [
        {text: 'Yes, I have an online store', value: true},
        {text: "No, I don’t have an online store", value: false},
      ],
      revenue: ['More than $5K USD', 'More than $10K USD', 'More than $25K USD', 'More than $50K USD'],

      answers: {
        about: '',
        sellingStatus: false,
        revenue: '',
      },
    }
  },
  beforeDestroy() {
    this.$store.commit('SET_ERROR_MESSAGE', '');
  },
  methods: {
    // setUserAnswers(isSkip = false) {
    //   this.$store.commit('SET_USER_INFO', {
    //     about: isSkip ? '' : this.answers.about,
    //     already_selling: isSkip ? false : !!this.answers.sellingStatus,
    //     current_revenue: isSkip ? '' : this.answers.revenue,
    //   });
    //   this.$router.push({name: 'SelectPlatform'});
    // },
    register(isSkip = false) {
      this.$store.dispatch('REGISTER',{
        company: {
          address: "",
          city: "",
          state: "",
          country: "",
          postal_code: "",
          timezone: "",
          users_attributes: [
            {
              first_name: "",
              last_name: "",
              phone_number: "",
              ...this.$store.getters.getUserInfo,
              about: isSkip ? '' : this.answers.about,
              already_selling: isSkip ? false : !!this.answers.sellingStatus,
              current_revenue: isSkip ? '' : this.answers.revenue,
            }
          ]
        }
      });
    }
  },
}
</script>
